<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between">
              <p class="mb-0 font-weight-bold">Alerta</p>
              <div clas="d-flex align-items-center justify-content-center">
                <CButton
                  color="primary"
                  class="m-1"
                  size="sm"
                  @click="prepareShowEndAlert(alerta)"
                >
                  Terminar Alerta
                </CButton>
                <p class="mb-0 font-weight-bold" v-if="alerta">Creada el: {{alerta.created_at}}</p>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <CRow v-if="alerta">
              <CCol col="12" xl="12">
                <CRow>
                  <CCol col="3" xl="3">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Usuario {{alerta.user.name}}</p>
                    </div>
                    <CRow>
                      <CCol col="4" xl="4">
												<div class="user-data">
													<img
														v-if="alerta.user.info.avatar"
														style="width: 100%; height: auto;"
														:src="alerta.user.info.avatar"
														class="c-mb-2"
													/>
													<div class="icon-user" v-else><font-awesome-icon icon="user"/></div>
												</div>
                      </CCol>
                      <CCol col="8" xl="8">
                        <p class="mb-0"><b>Email:</b> {{alerta.user.email}}</p>
                        <p class="mb-0"><b>Teléfono:</b> {{alerta.user.info.telefono}}</p>
                        <p class="mb-0"><b>Dirección:</b> {{alerta.user.info.direccion}}</p>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol col="4" xl="4">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Posición del usuario al generar la alerta</p>
                    </div>
                    <GmapMap v-if="alerta.datos.geo_usuario" :center="formatPosition(alerta.datos.geo_usuario)" :zoom="12"
                      class="map-container" style="height: 300px" >
                        <GmapMarker
                          :position="formatPosition(alerta.datos.geo_usuario)"
                          :clickable="true"
                          :draggable="true"
                        />
                    </GmapMap>
                  </CCol>
                  <CCol col="5" xl="5">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Grabación:</p>
                    </div>
										<div 
											v-if="loadingVideo"
											class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
											<CSpinner
												style="width:4rem;height:4rem;"
												color="danger"
											/>
											<p class="text-secondary my-2">Cargando grabación ...</p>
										</div>
										<video
											v-else-if="videoInfo"
											:src="videoInfo"
											controls
											style="max-height: 300px"
											class="w-100"></video>
										<p v-else class="text-secondary my-2">No hay video asociado</p>
									</CCol>
                </CRow>
              </CCol>
              <CCol class="d-flex flex-column justify-content-between align-items-between" col="12" xl="12" v-if="alerta.datos.camera">
                <CRow>
                  <CCol col="6" xl="6"> 
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Alerta generada en visualización de la siguiente cámara</p>
                    </div>
                    <p class="mb-0"><b>ID:</b> {{alerta.datos.camera.id}}</p>
                    <p class="mb-0"><b>Nombre:</b> {{alerta.datos.camera.name}}</p>
                    <p class="mb-0"><b>Url:</b> {{alerta.datos.camera.url}}</p>
                  </CCol>
                  <CCol col="6" xl="6"> 
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Ubicación de la Cámara</p>
                    </div>
                    <GmapMap v-if="alerta.datos.camera.latitude && alerta.datos.camera.longitude" :center="formatPosition(alerta.datos.camera)" :zoom="12"
                      class="map-container" style="height: 300px" >
                        <GmapMarker
                          :position="formatPosition(alerta.datos.camera)"
                          :clickable="true"
                          :draggable="true"
                        />
                    </GmapMap>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="alerta">
              <CCol col="12" xl="12">
                <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                  <p class="mb-0 ">Información Asociada a la Alerta</p>
                </div>
                <CDataTable
                  v-show="!loading && alerta.info.length > 0"
                  hover
                  striped
                  :items="alerta.info"
                  :fields="fields"
                  index-column
                >
                  <template #user="data">
                    <td>{{data.item.user.name}} <small>({{data.item.user.email}})</small></td>
                  </template>
                  <template #type="data">
                    <td><CBadge :color="getBadge(data.item.type)">{{getEstado(data.item.type)}}</CBadge></td>
                  </template>
                </CDataTable>
                <div class="text-center font-weight-bold" v-show="!loading && alerta.info.length === 0">Aún no hay datos para mostrar</div>
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>
      </transition>
			<ModalEndAlert :showModal="showEndAlert" :alertData="alertData" :closeModal="() => closeEndAlert()" />
    </CCol>
  </CRow>
</template>

<script>
import ModalEndAlert from '../notifications/ModalEndAlert'
import { getAlert, getVideoPublicUrl } from '../../api/alerts'

export default {
  name: 'Alert',
	components: {ModalEndAlert},
  data: () => {
    return {
      showEndAlert: false,
      alertData: null,
      loading: true,
      alerta: null,
			loadingVideo: true,
			videoInfo: null,
      fields: [
        {key: 'user', label: 'Usuario asociado'},
        {key: 'type', label: 'Tipo'},
        {key: 'info', label: 'Descripción'},
        {key: 'created_at', label: 'Fecha', _style:"min-width: 120px;"}
      ]
    }
  },
  mounted(){
    this.getAlert(this.$route.params.id)
  },
  methods: {
    prepareShowEndAlert(alert){
      this.alertData = alert
      this.showEndAlert = true
    },
    closeEndAlert(show = true){
      this.showEndAlert = false
      this.alertData = null
    },
    formatPosition(position){
      console.log("formatPosition: ", position)
      const geo = {
        lat: position.lat,
        lng: position.lng
      }
      return geo
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    getAlert(id){
      const data = { id }
      const self = this
      this.loading = true
      getAlert(data)
        .then(function(resp){
          self.alerta = resp.data
          self.loading = false
					self.loadingVideo = false
					if(self.alerta.videorecording && self.alerta.videorecording.file_path){
						self.showVideo(self.alerta.videorecording)
					}
        })
        .catch(function(error){
          console.log(error)
          self.loading = false
					self.loadingVideo = false
        })
    },
		async showVideo(info){
			this.loadingVideo = true
			const videourl = await getVideoPublicUrl({fileurl: info.file_path})
			this.loadingVideo = false
			if(videourl && videourl.url){
				this.videoInfo = videourl.url
			}
			else{
				this.videoInfo = null
			}
			console.log(this.videoInfo)
		},
    getBadge (status) {
      const info = {
          0: 'success',
          1: 'primary',
          2: 'primary',
          3: 'warning',
          4: 'warning',
          5: 'warning',
          6: 'warning',
          7: 'warning'
      }
      return info[status]
    },
    getEstado (status) {
      const info = {
          0: 'Cerrada en conformidad',
          1: 'Cerrada por inactividad',
          2: 'Cerrada por nula comunicación',
          3: 'Reabierta',
          4: 'Envío de ayuda a dirección',
          5: 'Comienzo alarmas en establecimiento',
          6: 'Comienzo Monitoreo',
          7: 'Término Monitoreo'
      }
      return info[status]
    }
  }
}
</script>
<!-- 

  Tipos de info alerta (por parte de administración)

  0: Cerrada en conformidad
  1: Cerrada por inactividad
  2: Cerrada por nula comunicación
  3: Reabierta
  4: Envío de ayuda a dirección
  5: Comienzo alarmas en establecimiento
  6: Comienzo Monitoreo
  7: Término Monitoreo

-->